import { relations } from 'drizzle-orm';
import {
  boolean,
  index,
  integer,
  json,
  jsonb,
  pgTable,
  primaryKey,
  text,
  timestamp,
  uuid,
} from 'drizzle-orm/pg-core';
import { type ICustomFields } from '~/server/services/card/types';
import { activitiesTable } from './activities';
import { cardTypesTable } from './card-types';
import { companyTypeEnum, priorityEnum } from './enums';
import { financeDocumentsTable } from './finance-documents';

export const cardsTable = pgTable(
  'Cards',
  {
    id: uuid('id').primaryKey().defaultRandom(),
    createdAt: timestamp('createdAt').defaultNow().notNull(),
    createdBy: text('createdBy').notNull(),
    updatedAt: timestamp('updatedAt').defaultNow().notNull(),
    updatedBy: text('updatedBy').notNull(),
    orgId: text('orgId').notNull(),
    cardTypeId: uuid('cardTypeId')
      .notNull()
      .references(() => cardTypesTable.id, { onDelete: 'cascade', onUpdate: 'cascade' }),
    name: text('name').notNull(),
    description: text('description'),
    firstName: text('firstName'),
    lastName: text('lastName'),
    jobTitle: text('jobTitle'),
    email: text('email'),
    phone: text('phone'),
    fax: text('fax'),
    totalRevenue: integer('totalRevenue'),
    annualRevenue: integer('annualRevenue'),
    website: text('website'),
    linkedIn: text('linkedIn'),
    twitter: text('twitter'),
    facebook: text('facebook'),
    instagram: text('instagram'),
    address: text('address'),
    postalCode: text('postalCode'),
    city: text('city'),
    street: text('street'),
    state: text('state'),
    zip: text('zip'),
    country: text('country'),
    timezone: text('timezone'),
    emailDomain: text('emailDomain'),
    industry: text('industry'),
    companyName: text('companyName'),
    priority: priorityEnum('priority'),
    note: text('note'),
    twitterId: text('twitterId'),
    language: text('language'),
    avatar: text('avatar'),
    dateOfBirth: timestamp('dateOfBirth'),
    age: timestamp('age'),
    leadStatus: text('leadStatus'),
    lifecycleStage: text('lifecycleStage'),
    numberOfEmployees: integer('numberOfEmployees'),
    customFields: jsonb('customFields').$type<ICustomFields>(),
    companyId: text('companyId'),
    socialSecurityNumber: text('socialSecurityNumber'),
    owner: text('owner'),
    type: companyTypeEnum('type'),
    public: boolean('isPublic').default(false).notNull(),
    source: text('source'),
    medium: text('medium'),
    campaign: text('campaign'),
    comment: text('comment'),
    yearFounded: integer('yearFounded'),
  },
  (table) => {
    return {
      orgIdIndex: index('orgIdIndex').on(table.orgId),
      cardTypeIdIndex: index('cardTypeIdIndex').on(table.cardTypeId),
      nameIndex: index('nameIndex').on(table.name),
    };
  },
);

export const cardsRelations = relations(cardsTable, ({ many, one }) => ({
  activities: many(activitiesTable, { relationName: 'cardActivities' }),
  cardType: one(cardTypesTable, {
    fields: [cardsTable.cardTypeId],
    references: [cardTypesTable.id],
  }),
  cards: many(cardsOnCardsTable, { relationName: 'originalCards' }),
  relatedCards: many(cardsOnCardsTable, { relationName: 'relatedCards' }),
  financeDocuments: many(financeDocumentsTable, { relationName: 'cardFinanceDocuments' }),
}));

export const cardsOnCardsTable = pgTable(
  'CardsOnCards',
  {
    cardId: uuid('cardId')
      .notNull()
      .references(() => cardsTable.id, { onDelete: 'cascade', onUpdate: 'cascade' }),
    relatedCardId: uuid('relatedCardId')
      .notNull()
      .references(() => cardsTable.id, { onDelete: 'cascade', onUpdate: 'cascade' }),
    values: json('values').$type<ICustomFields>(),
    updatedAt: timestamp('updatedAt').defaultNow().notNull(),
    updatedBy: text('updatedBy'),
    createdAt: timestamp('createdAt').defaultNow().notNull(),
    createdBy: text('createdBy'),
  },
  (table) => ({
    pk: primaryKey({ columns: [table.cardId, table.relatedCardId] }),
  }),
);
// many-to-many relation between example
// https://discord.com/channels/1043890932593987624/1111572543556550738/1148827742998114346
export const cardsOnCardsRelation = relations(cardsOnCardsTable, ({ one }) => ({
  card: one(cardsTable, {
    fields: [cardsOnCardsTable.cardId],
    references: [cardsTable.id],
    relationName: 'originalCards',
  }),
  relatedCard: one(cardsTable, {
    fields: [cardsOnCardsTable.relatedCardId],
    references: [cardsTable.id],
    relationName: 'relatedCards',
  }),
}));
