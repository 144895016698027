export const fieldTypes = [
  'ACCOUNTING',
  'CHECKBOX',
  'COLOR',
  'CURRENCY',
  'DATE',
  'EMAIL',
  'FILE',
  'HIDDEN',
  'IMAGE',
  'IMAGE_URL',
  'MULTI_SELECT',
  'NUMBER',
  'PASSWORD',
  'PHONE',
  'RADIO',
  'RANGE',
  'SINGLE_SELECT',
  'SOCIAL',
  'TEXT',
  'TEXTAREA',
  'TIME',
  'DATE_TIME',
  'AGE',
  'URL',
  'JSON',
  'LOOKUP_CARD',
  'LOOKUP_USER',
  'LOOKUP_ACTIVITY',
] as const;

export const numberTypes = ['NUMBER', 'RANGE', 'CURRENCY', 'ACCOUNTING'] as const;
