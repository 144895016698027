import { pgTable, text, timestamp, uuid } from 'drizzle-orm/pg-core';

export const apiTokensTable = pgTable('ApiTokens', {
  id: uuid('id').primaryKey().defaultRandom(),
  createdAt: timestamp('createdAt').defaultNow().notNull(),
  updatedAt: timestamp('updatedAt').defaultNow().notNull(),
  updatedBy: text('updatedBy'),
  orgId: text('orgId').notNull(),
  createdBy: text('createdBy').notNull(),
  name: text('name').default('API Token'),
  expiresAt: timestamp('expiresAt'),
  revokedAt: timestamp('revokedAt'),
});
