import { Group, Text, Tooltip } from '@mantine/core';
import { useHover } from '@mantine/hooks';
import { IconInfoCircle } from '@tabler/icons-react';

const asteriskStyle = { color: 'var(--input-asterisk-color,var(--mantine-color-error))' };

export type FormLabelProps = {
  label?: React.ReactNode;
  description?: React.ReactNode;
  required?: boolean;
  fullWidth?: boolean;
};

export const Label: React.FC<FormLabelProps> = ({ label, description, required, fullWidth = true }) => {
  const { ref, hovered } = useHover<HTMLDivElement>();

  if (!label) return null;
  const asteriskElement = required && <span style={asteriskStyle}> *</span>;
  return description ? (
    <Group
      ref={ref}
      gap={3}
      justify={fullWidth ? 'space-between' : undefined}
      w={fullWidth ? '100%' : undefined}
    >
      <Text size="sm" c="dimmed" inline display={'inline-block'} lh="md">
        {label}
        {asteriskElement}
      </Text>
      {hovered ? (
        <Tooltip multiline maw={230} label={description} openDelay={200} position="bottom">
          <Text size="sm" inline c="dimmed">
            <IconInfoCircle size={'1rem'} />
          </Text>
        </Tooltip>
      ) : null}
    </Group>
  ) : (
    <Text fz="sm" c="dimmed" inline display={'inline-block'} lh="md">
      {label}
      {asteriskElement}
    </Text>
  );
};
