import { relations } from 'drizzle-orm';
import { integer, jsonb, pgTable, text, timestamp, unique, uuid } from 'drizzle-orm/pg-core';
import { ICardTypeSettings, cardTypeSettings } from '~/server/services/card-type/settings.types';
import { cardLayoutsTable } from './card-layout';
import { cardsTable } from './cards';
import { cardsTableViewTable } from './cards-table-views';
import { fieldsTable } from './fields';
import { permissionsTable } from './roles-permissions';

const defaultCardTypeSettings = cardTypeSettings.parse({});

export const cardTypesTable = pgTable(
  'CardTypes',
  {
    id: uuid('id').primaryKey().defaultRandom(),
    createdAt: timestamp('createdAt').defaultNow().notNull(),
    createdBy: text('createdBy'),
    updatedBy: text('updatedBy'),
    updatedAt: timestamp('updatedAt').defaultNow().notNull(),
    name: text('name').notNull(),
    pluralName: text('pluralName').notNull(),
    description: text('description').notNull(),
    orgId: text('orgId').notNull(),
    slug: text('slug').notNull(),
    order: integer('order').default(100).notNull(),
    settings: jsonb('settings').$type<ICardTypeSettings>().default(defaultCardTypeSettings),
  },
  (table) => {
    return {
      idOrgIdKey: unique('CardType_id_orgId_key').on(table.id, table.orgId),
      slugOrgIdKey: unique('CardType_slug_orgId_key').on(table.orgId, table.slug),
    };
  },
);

export const cardTypeRelations = relations(cardTypesTable, ({ many }) => ({
  fields: many(fieldsTable),
  cards: many(cardsTable),
  permissions: many(permissionsTable),
  cardsTableViews: many(cardsTableViewTable),
  cardLayouts: many(cardLayoutsTable),
}));
