export const financeDocumentTypes = [
  'TAX_INVOICE', // חשבונית מס
  'RECEIPT', // קבלה
  'INVOICE_RECEIPT', // חשבונית מס קבלה
  'CREDIT_INVOICE', // חשבונית מס זיכוי
  'PROFORMA_INVOICE', // חשבון עסקה
  'ORDER_CONFIRMATION', // הזמנת עבודה
  'PRICE_OFFER', //   'הצעת מחיר'
  'SHIPPING_CERTIFICATE', //   'תעודת משלוח'
  'DEPOSIT', // 'הפקדה'
  'RETAINER', //  'ריטיינר'
  'INVOICE_FOR_RECEIPT', //   'חשבונית עבור קבלה'
  'DRAFTS', //   'טיוטות'
  'DONATION', //  'תרומה'
] as const;

export const notSupportedDocumentTypes = [
  'SHIPPING_CERTIFICATE', //   'תעודת משלוח'
  'DEPOSIT', // 'הפקדה'
  'RETAINER', //  'ריטיינר'
  'DRAFTS', //   'טיוטות'
];

export const supportedDocumentTypes = financeDocumentTypes.filter(
  (docType) => !notSupportedDocumentTypes.includes(docType),
);

export const financeDocumentStatuses = [
  'DRAFT', // טיוטה
  'PENDING', // ממתין
  'APPROVED', // אושר
  'REJECTED', // נדחה
  'CANCELED', // בוטל
] as const;
