import { json, pgTable, text, timestamp, unique, uuid } from 'drizzle-orm/pg-core';
import { type UserSettingsOptionsOutput } from '~/server/services/user-settings/types';

export const userSettingsTable = pgTable(
  'UserSettings',
  {
    id: uuid('id').primaryKey().defaultRandom(),
    createdAt: timestamp('createdAt').defaultNow().notNull(),
    updatedAt: timestamp('updatedAt').defaultNow(),
    updatedBy: text('updatedBy'),
    userId: text('userId').notNull(),
    orgId: text('orgId').notNull(),
    settings: json('settings').$type<UserSettingsOptionsOutput>().notNull(),
  },
  (table) => {
    return {
      uniqueUserIdOrgId: unique('uniqueUserIdOrgId').on(table.userId, table.orgId),
    };
  },
);
