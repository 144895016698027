import { type FieldError, type FieldErrorsImpl, type Merge } from 'react-hook-form';

export const parseFormErrorMessage = (
  message: string | FieldError | Merge<FieldError, FieldErrorsImpl<any>> | undefined,
): React.ReactNode => {
  if (typeof message === 'string') {
    return message;
  }

  if (typeof message?.message === 'string') {
    return message.message;
  }

  return null;
};
