import { relations } from 'drizzle-orm';
import { boolean, json, jsonb, pgTable, text, timestamp, unique, uuid } from 'drizzle-orm/pg-core';
import { type IFieldSetting } from '~/server/services/card-type/field.schema';
import { type FieldOption } from '../types';
import { cardTypesTable } from './card-types';
import { fieldTypeEnum } from './enums';

export const fieldsTable = pgTable(
  'Fields',
  {
    id: uuid('id').notNull().primaryKey().defaultRandom(),
    orgId: text('orgId').notNull(),
    label: text('label').notNull(),
    description: text('description'),
    options: jsonb('options').$type<FieldOption[]>(),
    required: boolean('required').default(false).notNull(),
    system: text('system').notNull(),
    type: fieldTypeEnum('type').notNull(),
    defaultValues: text('defaultValues').array().$type<string[]>(),
    defaultValue: text('defaultValue'),
    settings: json('settings').$type<IFieldSetting>(),
    internal: boolean('internal').default(false).notNull(),
    preDefinedColumn: boolean('preDefinedColumn').default(false).notNull(),
    createdAt: timestamp('createdAt').defaultNow().notNull(),
    updatedAt: timestamp('updatedAt').defaultNow(),
    updatedBy: text('updatedBy'),
    createdBy: text('createdBy'),
    cardTypeId: uuid('cardTypeId')
      .notNull()
      .references(() => cardTypesTable.id, { onDelete: 'cascade', onUpdate: 'cascade' }),
    relatedCardTypeId: uuid('relatedCardTypeId').references(() => cardTypesTable.id, {
      onDelete: 'cascade',
      onUpdate: 'cascade',
    }),
  },
  (table) => {
    return {
      cardTypeIdSystemKey: unique().on(table.cardTypeId, table.system),
    };
  },
);

export const fieldsRelations = relations(fieldsTable, ({ one }) => ({
  cardType: one(cardTypesTable, {
    fields: [fieldsTable.cardTypeId],
    references: [cardTypesTable.id],
  }),
}));
