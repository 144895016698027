import { relations } from 'drizzle-orm';
import { json, pgTable, text, timestamp, uuid } from 'drizzle-orm/pg-core';
import { type IAuditAction } from '~/server/services/audit/types';
import { automationTable } from './automation';

export const auditTable = pgTable('Audit', {
  id: uuid('id').primaryKey().defaultRandom(),
  createdAt: timestamp('createdAt').defaultNow().notNull(),
  orgId: text('orgId').notNull(),
  userId: text('userId').notNull(),
  action: text('action').$type<IAuditAction>().notNull(),
  oldData: json('oldData').$type<undefined | null | Record<string, any>>().default(null),
  newData: json('newData').$type<undefined | null | Record<string, any>>().default(null),
  automationId: uuid('automationId').references(() => automationTable.id, {
    onDelete: 'set null',
    onUpdate: 'set null',
  }),
});

export const auditRelations = relations(auditTable, ({ one }) => ({
  automation: one(automationTable, {
    fields: [auditTable.id],
    references: [automationTable.id],
    relationName: 'automation',
  }),
}));
