import dayjs from 'dayjs';
import objectSupport from 'dayjs/plugin/objectSupport';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { isDate } from 'is-what';
import { LocaleSettings, languageUtils } from './language';
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(objectSupport);

let localeSetttings = languageUtils.localeMap.he;

const setLocale = (locale: LocaleSettings) => {
  localeSetttings = locale;
};

export type TimeZone = Intl.DateTimeFormatOptions['timeZone'];

type DateOptions = {
  timeZone?: TimeZone;
};

const formatDate = (date: Date | string | undefined | number, { timeZone }: DateOptions = {}) => {
  if (!date) return '';

  const realDate =
    typeof date === 'string' ? new Date(date) : typeof date === 'number' ? new Date(date) : date;
  if (!isValidDate(realDate)) return '';
  return new Intl.DateTimeFormat(localeSetttings.lang, { timeZone }).format(realDate);
};

const formatSignificant = (date: Date | string | undefined | number, { timeZone }: DateOptions = {}) => {
  if (!date) return '';
  const realDate =
    typeof date === 'string' ? new Date(date) : typeof date === 'number' ? new Date(date) : date;
  if (!isValidDate(realDate)) return '';
  // if it's today, return the time
  if (dayjs().isSame(realDate, 'day')) {
    return new Intl.DateTimeFormat(localeSetttings.lang, {
      hour: 'numeric',
      minute: 'numeric',
      hour12: false,
      timeZone,
    }).format(realDate);
  }
  // otherwise return the date
  return new Intl.DateTimeFormat(localeSetttings.lang, {
    day: 'numeric',
    month: 'numeric',
    year: 'numeric',
  }).format(realDate);
};

const toDate = (date: unknown): Date | null => {
  if (dayjs.isDayjs(date)) {
    return date.toDate();
  }
  if (isDate(date)) {
    return date;
  }
  if (typeof date === 'string' || typeof date === 'number') {
    return new Date(date);
  }
  return null;
};

const tryFormatDate = (date: unknown, { timeZone }: DateOptions = {}) => {
  const safeDate = toDate(date);
  if (!safeDate) return '';
  return formatDate(safeDate, { timeZone });
};

const tryFormatDateTime = (date: unknown, { timeZone }: DateOptions = {}) => {
  const safeDate = toDate(date);
  if (!safeDate) return '';
  return formatDateTime(safeDate, { timeZone });
};

const formatDateTime = (date: Date | string | number, { timeZone }: DateOptions = {}) => {
  const realDate = typeof date === 'string' ? new Date(date) : date;
  return new Intl.DateTimeFormat(localeSetttings.lang, {
    day: 'numeric',
    month: 'numeric',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    timeZone,
  }).format(realDate);
};

const parseDateString = (date: string) => {
  const delimiter = /[.-/]/;
  const [day = '', month = '', year = ''] = date.split(delimiter);

  return { day, month, year };
};
const parse = (date: string) => {
  const { day, month, year } = parseDateString(date);
  return new Date(`${year}-${month}-${day}`);
};
const parseAsUtc = (date: string) => {
  const { day, month, year } = parseDateString(date);
  return new Date(Date.UTC(+year, +month - 1, +day));
};

const isValidDate = (date?: Date | string) => date instanceof Date && !Number.isNaN(date.getTime());
const asUtc = (date: Date | string) => new Date(new Date(date).getTime());

const createDateFromUtc = (date: Date, hours = 0, minutes = 0) => {
  const validDate = isValidDate(date) ? date : new Date(date);

  return new Date(
    validDate.getUTCFullYear(),
    validDate.getUTCMonth(),
    validDate.getUTCDate(),
    hours,
    minutes,
  );
};

const parseMinSec = (value?: string): number[] => {
  if (!value) return [0, 0];
  return value.split(':').map((n) => +n);
};

const relativeTime = (timestamp?: Date, now = new Date()): string => {
  if (!timestamp) return '';
  let value;
  const diff = (now.getTime() - timestamp.getTime()) / 1000;
  const minutes = Math.round(diff / 60);
  const hours = Math.round(minutes / 60);
  const days = Math.round(hours / 24);
  const months = Math.round(days / 30);
  const years = Math.round(months / 12);
  const rtf = new Intl.RelativeTimeFormat(localeSetttings.locale, { numeric: 'auto' });

  if (Math.abs(years) > 0) {
    value = rtf.format(0 - years, 'year');
  } else if (Math.abs(months) > 0) {
    value = rtf.format(0 - months, 'month');
  } else if (Math.abs(days) > 0) {
    value = rtf.format(0 - days, 'day');
  } else if (Math.abs(hours) > 0) {
    value = rtf.format(0 - hours, 'hour');
  } else if (Math.abs(minutes) > 0) {
    value = rtf.format(0 - minutes, 'minute');
  } else {
    value = rtf.format(0 - 1, 'minute');
  }
  return value;
};

const timestampAsObject = (
  timestamp: Date,
): {
  day: number;
  month: string;
  year: number;
  time: string;
} => ({
  day: timestamp.getDate(),
  month: new Intl.DateTimeFormat(localeSetttings.lang, {
    month: 'short',
  }).format(timestamp),
  year: timestamp.getFullYear(),
  time: new Intl.DateTimeFormat(localeSetttings.lang, {
    hour: 'numeric',
    minute: 'numeric',
    hour12: false,
  }).format(timestamp),
});

const subtractMilliseconds = (date: Date, milliseconds: number): Date =>
  new Date(date.getTime() - milliseconds);
const getDiffInMilliseconds = (date1: Date, date2: Date): number => date1.getTime() - date2.getTime();
const addMilliseconds = (date: Date, milliseconds: number): Date =>
  new Date(date.getTime() + milliseconds);
const isAfter = (date1: Date, date2: Date): boolean => date1.getTime() > date2.getTime();
const isBefore = (date1: Date, date2: Date): boolean => date1.getTime() < date2.getTime();

export const relativeTimeOptions = [
  'today',
  'yesterday',
  'last-7-days',
  'last-30-days',
  'this-week',
  'last-week',
  'this-month',
  'last-month',
  'this-year',
  'last-year',
  'until-now',
  'from-today',
] as const;

export type RelativeTime = (typeof relativeTimeOptions)[number];

const mapRelativeToValue: Record<RelativeTime, (timeZone: TimeZone) => [Date, Date | null]> = {
  today: (timeZone: TimeZone) => [
    dayjs().tz(timeZone).startOf('day').toDate(),
    dayjs().tz(timeZone).endOf('day').toDate(),
  ],
  'last-7-days': (timeZone: TimeZone) => [
    dayjs().tz(timeZone).subtract(7, 'day').startOf('day').toDate(),
    dayjs().tz(timeZone).toDate(),
  ],
  yesterday: (timeZone: TimeZone) => [
    dayjs().tz(timeZone).subtract(1, 'day').startOf('day').toDate(),
    dayjs().tz(timeZone).subtract(1, 'day').endOf('day').toDate(),
  ],
  'this-week': (timeZone: TimeZone) => [
    dayjs().tz(timeZone).startOf('week').toDate(),
    dayjs().tz(timeZone).endOf('week').toDate(),
  ],
  'last-week': (timeZone: TimeZone) => [
    dayjs().tz(timeZone).subtract(1, 'week').startOf('week').toDate(),
    dayjs().tz(timeZone).subtract(1, 'week').endOf('week').toDate(),
  ],
  'last-30-days': (timeZone: TimeZone) => [
    dayjs().tz(timeZone).subtract(30, 'day').toDate(),
    dayjs().tz(timeZone).toDate(),
  ],
  'this-month': (timeZone: TimeZone) => [
    dayjs().tz(timeZone).startOf('month').toDate(),
    dayjs().tz(timeZone).endOf('month').toDate(),
  ],
  'last-month': (timeZone: TimeZone) => [
    dayjs().tz(timeZone).subtract(1, 'month').startOf('month').toDate(),
    dayjs().tz(timeZone).subtract(1, 'month').endOf('month').toDate(),
  ],
  'this-year': (timeZone: TimeZone) => [
    dayjs().tz(timeZone).startOf('year').toDate(),
    dayjs().tz(timeZone).endOf('year').toDate(),
  ],
  'last-year': (timeZone: TimeZone) => [
    dayjs().tz(timeZone).subtract(1, 'year').startOf('year').toDate(),
    dayjs().tz(timeZone).subtract(1, 'year').endOf('year').toDate(),
  ],
  'until-now': (timeZone: TimeZone) => [dayjs(0).toDate(), dayjs().tz(timeZone).toDate()],
  'from-today': (timeZone: TimeZone) => [dayjs().tz(timeZone).startOf('day').toDate(), null],
} as const;

const toUnixSeconds = (date: Date): number => Math.floor(date.getTime() / 1000);

const formatTime = (date: Date | string | number, { timeZone }: DateOptions = {}) => {
  const realDate = typeof date === 'string' ? new Date(date) : date;
  return new Intl.DateTimeFormat(localeSetttings.lang, {
    hour: 'numeric',
    minute: 'numeric',
    hour12: false,
    timeZone,
  }).format(realDate);
};

const formatTimeFrame = (
  start: Date | string | number | null,
  end: Date | string | number | null,
  options?: DateOptions,
) => {
  if (dayjs(start).isSame(end, 'day')) {
    return `${start ? formatTime(start, options) : 'N/A'} - ${end ? formatTime(end, options) : 'N/A'}`;
  }

  return `${start ? formatDateTime(start) : 'N/A'} - ${end ? formatDateTime(end, options) : 'N/A'}`;
};

const toAge = (date: unknown): string => {
  const safeDate = toDate(date);
  if (!safeDate) return '';
  const now = new Date();
  const diff = now.getTime() - safeDate.getTime();
  const age = Math.floor(diff / (1000 * 60 * 60 * 24 * 365));
  return age.toString();
};

const commonTimeZones = [
  'Asia/Jerusalem', // IST
  'America/Aruba', // AST
  'Asia/Shanghai', // CST
  'America/New_York', // EST
  'Europe/London', // GMT
  'Asia/Kolkata', // IST
  'Asia/Tokyo', // JST
  'America/Los_Angeles', // PT
  'Europe/Budapest', // CET
] as const;

const langToTimeZone = {
  he: 'Asia/Jerusalem',
  en: 'America/New_York',
  hu: 'Europe/Budapest',
};

const mapNumberToDay = {
  0: 'Sunday',
  1: 'Monday',
  2: 'Tuesday',
  3: 'Wednesday',
  4: 'Thursday',
  5: 'Friday',
  6: 'Saturday',
} as const;
type Day = (typeof mapNumberToDay)[keyof typeof mapNumberToDay];

const numberToDay = (day: number): Day | null => {
  if (day < 0 || day > 6) return null;
  return mapNumberToDay[day as keyof typeof mapNumberToDay] as Day;
};

const allTimezones = (Intl?.supportedValuesOf('timeZone') as ['Asia/Jerusalem']) ?? [];

export const dateTimeService = {
  format: formatDate,
  tryFormat: tryFormatDate,
  formatDateTime,
  formatTime,
  formatTimeFrame,
  tryFormatDateTime,
  formatSignificant,
  parse,
  toAge,
  isValidDate,
  asUtc,
  createDateFromUtc,
  parseMinSec,
  parseAsUtc,
  relativeTime,
  timestampAsObject,
  subtractMilliseconds,
  addMilliseconds,
  getDiffInMilliseconds,
  isAfter,
  toUnixSeconds,
  isBefore,
  mapRelativeToValue,
  timeZones: { common: commonTimeZones, langToTimeZone, all: allTimezones },
  numberToDay,
  setLocale,
};
