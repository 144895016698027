import { json, pgTable, text, timestamp, uuid } from 'drizzle-orm/pg-core';
import { type PlanSettingOutput } from '~/server/services/plan/types';
import { planTypeEnum } from './enums';

export const plansTable = pgTable('PlansTable', {
  id: uuid('id').primaryKey().defaultRandom(),
  orgId: text('orgId').unique().notNull(),
  createdAt: timestamp('createdAt').defaultNow().notNull(),
  updatedAt: timestamp('updatedAt').defaultNow(),
  updatedBy: text('updatedBy').notNull(),
  createdBy: text('createdBy').notNull(),
  type: planTypeEnum('type').default('TRIAL').notNull(),
  settings: json('settings').notNull().$type<PlanSettingOutput>(),
});
