import { relations } from 'drizzle-orm';
import { json, pgEnum, pgTable, text, timestamp, uuid } from 'drizzle-orm/pg-core';
import {
  type AutomationAction,
  type AutomationCondition,
  type AutomationTrigger,
} from '~/server/services/automation/automation.schema';
import { auditTable } from './audit';

export const automationStatusEnum = pgEnum('automationStatus', [
  'active',
  'inactive',
  'deleted',
  'plan_limit',
]);

export const automationTable = pgTable('Automation', {
  id: uuid('id').primaryKey().defaultRandom(),
  createdAt: timestamp('createdAt').defaultNow().notNull(),
  orgId: text('orgId').notNull(),
  createdBy: text('createdBy').notNull(),
  updatedAt: timestamp('updatedAt').defaultNow().notNull(),
  updatedBy: text('updatedBy').notNull(),
  cardTypeSlug: text('cardTypeSlug').notNull(),
  description: text('description').notNull(),
  status: automationStatusEnum('status').default('active').notNull(),
  trigger: json('trigger').$type<AutomationTrigger>().notNull(),
  conditions: json('conditions').$type<AutomationCondition[]>().notNull(),
  actions: json('actions').$type<AutomationAction[]>().notNull(),
});

export const automationRelations = relations(automationTable, ({ many }) => ({
  audits: many(auditTable),
}));
