import { type ColumnBaseConfig, type ColumnDataType, relations } from 'drizzle-orm';
import {
  type PgColumn,
  boolean,
  doublePrecision,
  integer,
  json,
  pgTable,
  text,
  timestamp,
  unique,
  uuid,
} from 'drizzle-orm/pg-core';
import { type IFinanceDocumentPayment } from '~/server/services/finance-documents/schemas/payment-methods.schemas';
import {
  type IFinanceDocumentCustomer,
  type IFinanceDocumentDiscount,
  type IFinanceDocumentProduct,
  type IFinanceSettings,
} from '~/server/services/finance-documents/schemas/schemas';
import { cardsTable } from './cards';
import {
  currencyEnum,
  financeDocumentStatusEnum,
  financeDocumentTypesEnum,
  languageEnum,
} from './enums';

export const financeDocumentsTable = pgTable(
  'FinanceDocuments',
  {
    id: uuid('id').primaryKey().defaultRandom(),
    orgId: text('orgId').notNull(),
    createdAt: timestamp('createdAt').defaultNow().notNull(),
    createdBy: text('createdBy').notNull(),
    updatedAt: timestamp('updatedAt').defaultNow(),
    updatedBy: text('updatedBy'),
    parentDocumentId: uuid('parentDocumentId').references(
      (): PgColumn<ColumnBaseConfig<ColumnDataType, string>> => financeDocumentsTable.id,
      {
        onDelete: 'set null',
        onUpdate: 'cascade',
      },
    ),
    cardId: uuid('cardId').references(() => cardsTable.id, {
      onDelete: 'set null',
      onUpdate: 'cascade',
    }),
    customer: json('customer').$type<IFinanceDocumentCustomer>(),
    type: financeDocumentTypesEnum('type').notNull(),
    serialNumber: integer('serialNumber'),
    language: languageEnum('language').default('he').notNull(),
    currency: currencyEnum('currency').default('ILS').notNull(),
    status: financeDocumentStatusEnum('status').default('DRAFT').notNull(),
    date: timestamp('date').notNull(),
    paymentDate: timestamp('paymentDate'),
    discount: json('discount').$type<IFinanceDocumentDiscount>(),
    totalBeforeVat: doublePrecision('totalBeforeVat').default(0).notNull(),
    totalVat: doublePrecision('totalVat').default(0).notNull(),
    total: doublePrecision('total').default(0).notNull(),
    totalPaid: doublePrecision('totalPaid').default(0).notNull(),
    roundTotal: boolean('roundTotal').default(true).notNull(),
    balance: doublePrecision('balance').default(0).notNull(),
    vatPercentage: doublePrecision('vatPercentage').notNull(),
    products: json('products').$type<IFinanceDocumentProduct[]>(),
    payments: json('payments').$type<IFinanceDocumentPayment[]>(),
    notes: text('notes'),
  },
  (table) => {
    return {
      uniqueSerialNumber: unique('uniqueSerialNumber').on(table.orgId, table.type, table.serialNumber),
    };
  },
);

export const financeDocumentsRelations = relations(financeDocumentsTable, ({ one, many }) => ({
  card: one(cardsTable, {
    fields: [financeDocumentsTable.cardId],
    references: [cardsTable.id],
    relationName: 'cardFinanceDocuments',
  }),
  parentDocument: one(financeDocumentsTable, {
    fields: [financeDocumentsTable.parentDocumentId],
    references: [financeDocumentsTable.id],
    relationName: 'parentDocument',
  }),
  childDocuments: many(financeDocumentsTable, {
    relationName: 'parentDocument',
  }),
}));

export const financeSettingsTable = pgTable(
  'FinanceSettings',
  {
    id: uuid('id').primaryKey().defaultRandom(),
    orgId: text('orgId').notNull(),
    settings: json('settings').$type<IFinanceSettings>().notNull(),
    updatedAt: timestamp('updatedAt').defaultNow(),
    updatedBy: text('updatedBy'),
    createdAt: timestamp('createdAt').defaultNow().notNull(),
    createdBy: text('createdBy').notNull(),
  },
  (table) => {
    return {
      uniqueOrgId: unique('uniqueOrgId').on(table.orgId),
    };
  },
);
