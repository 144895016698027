import { relations } from 'drizzle-orm';
import { boolean, pgEnum, pgTable, text, timestamp, unique, uuid } from 'drizzle-orm/pg-core';
import { cardTypesTable } from './card-types';

export const collaborationTypeEnum = pgEnum('Collaboration', ['ORGANIZATION', 'ROLE_GROUP', 'USER']);

export const permissionTypeEnum = pgEnum('PermissionType', [
  'CARDS',
  'AUTOMATION',
  'TASKS',
  'STORAGE',
  'AUDIT',
  'MEETINGS',
  'CARD_TYPES',
  'CARD_FIELDS',
  'CARD_LAYOUTS',
  'CARDS_TABLE_VIEWS',
  'ROLES',
  'ADMIN_CONSOLE',
  'ORGANIZATION',
  'FINANCE_DOCUMENTS',
  'SMS',
]);

export const rolesTable = pgTable('Roles', {
  id: uuid('id').primaryKey().defaultRandom(),
  name: text('name').notNull(),
  orgId: text('orgId').notNull(),
  createdAt: timestamp('createdAt').defaultNow().notNull(),
  createdBy: text('createdBy').notNull(),
  updatedAt: timestamp('updatedAt').defaultNow().notNull(),
  updatedBy: text('updatedBy').notNull(),
});

export const permissionsTable = pgTable(
  'Permissions',
  {
    id: uuid('id').primaryKey().defaultRandom(),
    type: permissionTypeEnum('type').notNull(),
    cardTypeId: uuid('cardTypeId').references(() => cardTypesTable.id, {
      onDelete: 'cascade',
      onUpdate: 'cascade',
    }),
    create: boolean('create'),
    read: boolean('read'),
    update: boolean('update'),
    delete: boolean('delete'),
    collaboration: collaborationTypeEnum('collaboration'),
    orgId: text('orgId').notNull(),
    updatedAt: timestamp('updatedAt').defaultNow().notNull(),
    updatedBy: text('updatedBy').notNull(),
    createdAt: timestamp('createdAt').defaultNow().notNull(),
    createdBy: text('createdBy').notNull(),
    roleId: uuid('roleId')
      .notNull()
      .references(() => rolesTable.id, { onDelete: 'cascade', onUpdate: 'cascade' }),
  },
  (table) => ({
    roleIdTypeCardTypeId: unique('Permissions_roleId_type_cardTypeId').on(
      table.roleId,
      table.type,
      table.cardTypeId,
    ),
  }),
);

export const userRolesTable = pgTable(
  'UserRoles',
  {
    id: uuid('id').primaryKey().defaultRandom(),
    userId: text('userId').notNull(),
    roleId: uuid('roleId')
      .notNull()
      .references(() => rolesTable.id, { onDelete: 'cascade', onUpdate: 'cascade' }),
    orgId: text('orgId').notNull(),
    owner: boolean('owner').default(false).notNull(),
    createdAt: timestamp('createdAt').defaultNow().notNull(),
    createdBy: text('createdBy').notNull(),
    updatedAt: timestamp('updatedAt').defaultNow().notNull(),
    updatedBy: text('updatedBy').notNull(),
  },
  (table) => ({
    userIdOrgId: unique('UserRoles_userId_orgId').on(table.userId, table.orgId),
  }),
);

export const userRolesRelations = relations(userRolesTable, ({ one }) => ({
  role: one(rolesTable, {
    fields: [userRolesTable.roleId],
    references: [rolesTable.id],
  }),
}));

export const roleRelations = relations(rolesTable, ({ many }) => ({
  permissions: many(permissionsTable),
  users: many(userRolesTable),
  childOrgs: many(roleAccessToOrganizationTable),
}));

export const permissionsRelations = relations(permissionsTable, ({ one }) => ({
  role: one(rolesTable, {
    fields: [permissionsTable.roleId],
    references: [rolesTable.id],
  }),
  cardType: one(cardTypesTable, {
    fields: [permissionsTable.cardTypeId],
    references: [cardTypesTable.id],
  }),
}));

// Orgs can have sub-orgs
export const organizationRelationsTable = pgTable(
  'OrganizationRelations',
  {
    id: uuid('id').primaryKey().defaultRandom(),
    orgId: text('orgId').notNull(),
    childOrgId: text('childId').notNull(),
    createdAt: timestamp('createdAt').defaultNow().notNull(),
    createdBy: text('createdBy').notNull(),
    updatedAt: timestamp('updatedAt').defaultNow().notNull(),
    updatedBy: text('updatedBy'),
  },
  (table) => ({
    orgIdChildOrgId: unique('OrganizationRelations_orgId_childOrgId').on(table.orgId, table.childOrgId),
  }),
);

// access to sub-orgs via roles
export const roleAccessToOrganizationTable = pgTable(
  'RoleAccessToOrganization',
  {
    id: uuid('id').primaryKey().defaultRandom(),
    orgId: text('orgId').notNull(),
    childOrgId: text('childOrgId').notNull(),
    roleId: uuid('roleId')
      .notNull()
      .references(() => rolesTable.id, { onDelete: 'cascade', onUpdate: 'cascade' }),
    createdAt: timestamp('createdAt').defaultNow().notNull(),
    createdBy: text('createdBy').notNull(),
    updatedAt: timestamp('updatedAt').defaultNow().notNull(),
    updatedBy: text('updatedBy'),
  },
  (table) => ({
    roleIdChildOrgId: unique('RoleAccessToOrganization_roleId_childOrgId').on(
      table.roleId,
      table.childOrgId,
    ),
  }),
);

export const roleAccessToOrganizationRelations = relations(roleAccessToOrganizationTable, ({ one }) => ({
  role: one(rolesTable, {
    fields: [roleAccessToOrganizationTable.roleId],
    references: [rolesTable.id],
  }),
  childOrg: one(organizationRelationsTable, {
    fields: [roleAccessToOrganizationTable.childOrgId],
    references: [organizationRelationsTable.childOrgId],
  }),
}));
