import { z } from 'zod';
import { regexSchemas } from './regex';

const literalSchema = z.union([z.string(), z.number(), z.boolean(), z.null()]);
type Literal = z.infer<typeof literalSchema>;
export type JsonType = Literal | { [key: string]: JsonType } | JsonType[];

const jsonSchema: z.ZodType<JsonType> = z.lazy(() =>
  z.union([literalSchema, z.array(jsonSchema), z.record(jsonSchema)]),
);

const numberSchema = z.preprocess((x) => Number(x), z.number());

const slugSchema = z
  .string()
  .regex(
    regexSchemas.slug,
    'Slug must be lowercase and contain only letters, numbers, and dashes. "my-slug" is valid, "My Slug" is not.',
  )
  .transform((val) =>
    val
      .toLowerCase()
      .replace(/[^\w-]+/g, '')
      .replace(/ /g, '-'),
  );

const snakeCaseSchema = z
  .string()
  .regex(
    regexSchemas.snakeCase,
    'Slug must contain only letters, numbers, and underscores. "my_slug" is valid, "My Slug" is not.',
  )
  .transform((val) => val.replace(/[^\w-]+/g, '').replace(/ /g, '_'));

const emailSchema = z.string().trim().email();

const isEmail = (value: unknown): value is string => {
  if (typeof value !== 'string') return false;
  if (zodUtils.emailSchema.safeParse(value).success) return true;
  return false;
};
const filterValidEmails = (emails: unknown[]): string[] => {
  return emails.filter<string>(isEmail);
};
const phoneSchema = z.string().regex(regexSchemas.phone, 'Invalid phone number');

const formatError = (e: z.ZodError) => {
  return e.errors
    .map((error) => {
      if (error.path.length === 0) {
        return error.message;
      }

      const path = error.path
        .map((path) => {
          if (typeof path === 'number') {
            return `[item ${path + 1}]`;
          }
          return path;
        })
        .join('.');
      return `${path} - ${error.message}`;
    })
    .join('\n');
};

// 06:25
const timeSchema = z
  .string()
  .regex(/^(?:[01]\d|2[0-3]):[0-5]\d$/, 'Invalid time format. Expected HH:MM (e.g., "06:25").');

const urlSchema = z.preprocess((val) => {
  if (typeof val !== 'string') return val;

  if (val && /https?:\/\//.test(val) === false) {
    return `https://${val}`;
  }
  return val;
}, z.string().url());

export const zodUtils = {
  jsonSchema,
  slugSchema,
  phoneSchema,
  snakeCaseSchema,
  numberSchema,
  emailSchema,
  urlSchema,
  filterValidEmails,
  formatError,
  timeSchema,
};
