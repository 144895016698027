import { pgEnum } from 'drizzle-orm/pg-core';
import { fieldTypes } from './consts/fields';
import { financeDocumentStatuses, financeDocumentTypes } from './consts/finance';
import {
  companyTypes,
  currencies,
  dealStages,
  dealTypes,
  languages,
  planTypes,
  priorities,
} from './consts/general';

export const priorityEnum = pgEnum('Priority', priorities);
export const fieldTypeEnum = pgEnum('FieldType', fieldTypes);
export const companyTypeEnum = pgEnum('CompanyType', companyTypes);
export const dealStageEnum = pgEnum('DealStage', dealStages);
export const dealTypeEnum = pgEnum('DealType', dealTypes);
export const planTypeEnum = pgEnum('Plan', planTypes);
export const languageEnum = pgEnum('Language', languages);
export const currencyEnum = pgEnum('Currency', currencies);
export const financeDocumentTypesEnum = pgEnum('FinanceDocumentsTypes', financeDocumentTypes);
export const financeDocumentStatusEnum = pgEnum('FinanceDocumentsStatuses', financeDocumentStatuses);
