export const languages = ['en', 'he', 'hu'] as const;
export const currencies = ['ILS', 'USD', 'HUF', 'GBP', 'EUR', 'JPY', 'CAD', 'PLN'] as const;
export const priorities = ['URGENT', 'HIGH', 'LOW', 'MEDIUM'] as const;
export const companyTypes = [
  'INVESTOR',
  'OTHER',
  'PARTNER',
  'PROSPECT',
  'RESELLER',
  'SUPPLIER',
  'VENDOR',
] as const;
export const dealStages = [
  'APPOINTMENT_SCHEDULED',
  'CLOSED_LOST',
  'CLOSED_WON',
  'CONTRACT_SENT',
  'DECISION_MAKER_BOUGHT_IN',
  'PRESENTATION_SCHEDULED',
  'QUALIFIED_TO_BUY',
] as const;
export const dealTypes = ['NEW_BUSINESS', 'OTHER', 'RENEWAL', 'UPSALE'] as const;
export const planTypes = ['TRIAL', 'FREE', 'STARTER', 'PRO', 'ENTERPRISE'] as const;
