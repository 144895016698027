import { relations } from 'drizzle-orm';
import { boolean, json, pgEnum, pgTable, text, timestamp, uuid } from 'drizzle-orm/pg-core';
import { type IActivitySettings } from '~/server/services/activity/types';
import { cardsTable } from './cards';

export const communicationTypes = [
  'CALL',
  'SMS',
  'MAIL',
  'COMMENT',
  'WHATSAPP',
  'FACEBOOK',
  'INSTAGRAM',
  'LINKEDIN',
  'TWITTER',
] as const;

export const eventTypes = ['TASK', 'MEETING'] as const;
export const emailGuestsTypes = ['MAIL', 'MEETING'] as const;
export const allActivityTypes = [...eventTypes, ...communicationTypes] as const;
export const activityTypeEnum = pgEnum('ActivityType', allActivityTypes);

export const activitiesTable = pgTable('Activities', {
  id: uuid('id').primaryKey().defaultRandom(),
  createdAt: timestamp('createdAt').defaultNow().notNull(),
  createdBy: text('createdBy').notNull(),
  updatedAt: timestamp('updatedAt').defaultNow(),
  updatedBy: text('updatedBy'),
  orgId: text('orgId').notNull(),
  cardId: uuid('cardId').references(() => cardsTable.id, {
    onDelete: 'set null',
    onUpdate: 'cascade',
  }),
  type: activityTypeEnum('type').notNull(),
  sendFromSystem: boolean('sendFromSystem').default(false),
  date: timestamp('date').notNull(),
  endDate: timestamp('endDate'),
  description: text('description'),
  title: text('title'),
  inbound: boolean('inbound'),
  guests: text('guests').array(),
  assignedTo: text('assignedTo'),
  attachmentFilenames: text('attachmentFilenames').array(),
  reminder: timestamp('reminder'),
  location: text('location'),
  done: boolean('done'),
  settings: json('settings').$type<IActivitySettings>(),
});

export const activitiesRelations = relations(activitiesTable, ({ one }) => ({
  card: one(cardsTable, {
    fields: [activitiesTable.cardId],
    references: [cardsTable.id],
    relationName: 'cardActivities',
  }),
}));
