import { type Direction } from '@mantine/core';
import { type DayOfWeek } from '@mantine/dates';
import { windowService } from '~/core/window';
import { languages } from '~db/schema/consts/general';
import { type AccountSettingsTable, type Language } from '~db/types';

const languageCookieKey = 'language';

export type LocaleSettings = {
  locale: Language;
  dir: Direction;
  align: 'left' | 'right';
  alignContent: 'left' | 'right';
  lang: 'he-IL' | 'en-US' | 'hu-HU';
  firstDayOfWeek: 0 | 1;
  weekendDays: DayOfWeek[];
};

export type SupportedLanguage = (typeof languages)[number];

const localeMap: Record<Language, LocaleSettings> = {
  he: {
    locale: 'he',
    lang: 'he-IL',
    dir: 'rtl',
    firstDayOfWeek: 0,
    align: 'right',
    alignContent: 'left',
    weekendDays: [5, 6],
  },
  en: {
    locale: 'en',
    lang: 'en-US',
    dir: 'ltr',
    firstDayOfWeek: 1,
    align: 'left',
    alignContent: 'right',
    weekendDays: [0, 6],
  },
  hu: {
    locale: 'hu',
    lang: 'hu-HU',
    dir: 'ltr',
    firstDayOfWeek: 1,
    align: 'left',
    alignContent: 'right',
    weekendDays: [0, 6],
  },
} as const;

const mapBrowserLanguageToLanguage: Record<string, Language> = {
  'en-US': 'en',
  'he-IL': 'he',
  'hu-HU': 'hu',
  en: 'en',
  he: 'he',
  hu: 'hu',
};

const getDefault = ({
  cookie,
  settings,
}: { cookie?: string; settings?: AccountSettingsTable['settings'] } = {}) => {
  const language = settings?.language ?? cookie ?? windowService.language ?? 'he';
  const detected = mapBrowserLanguageToLanguage[language];
  if (detected) return detected;

  // Fallbacks if no language detected
  if (language.includes('he')) return 'he';
  if (language.includes('en')) return 'en';
  if (language.includes('hu')) return 'hu';

  return 'en';
};

const isSupportedLanguage = (value: string | undefined): value is SupportedLanguage => {
  return languages.includes(value as SupportedLanguage);
};

const getLocaleSettings = (language: Language): LocaleSettings => {
  return localeMap[language];
};

export const languageUtils = {
  languageCookieKey,
  isSupportedLanguage,
  getDefault,
  getLocaleSettings,
  localeMap,
};
