import { z } from 'zod';

export const cardTypeSettings = z.object({
  isHidden: z.boolean().optional(),
  uniqueIdentifiers: z
    .array(z.string())
    .optional()
    .transform((fields) => {
      if (!fields) return [];
      return Array.from(new Set(fields));
    }),
});

export type ICardTypeSettings = z.infer<typeof cardTypeSettings>;
