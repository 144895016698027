import { relations, sql } from 'drizzle-orm';
import { integer, jsonb, pgTable, text, timestamp, unique, uuid } from 'drizzle-orm/pg-core';
import { type ICardsTableViewSettings } from '~/Components/Cards/types';
import { cardLayoutsTable } from './card-layout';
import { cardTypesTable } from './card-types';

export const cardsTableViewTable = pgTable(
  'CardsTableView',
  {
    id: uuid('id').primaryKey().defaultRandom(),
    title: text('title').notNull(),
    description: text('description'),
    settings: jsonb('settings').notNull().$type<ICardsTableViewSettings>(),
    cardTypeId: uuid('cardTypeId')
      .notNull()
      .references(() => cardTypesTable.id, { onDelete: 'cascade', onUpdate: 'cascade' }),
    layoutId: uuid('layoutId').references(() => cardLayoutsTable.id, {
      onDelete: 'cascade',
      onUpdate: 'cascade',
    }),
    slug: text('slug').notNull().default(sql`substring(md5(random()::text), 0, 8)`),
    createdAt: timestamp('createdAt').defaultNow().notNull(),
    createdBy: text('createdBy').notNull(),
    updatedAt: timestamp('updatedAt').defaultNow(),
    updatedBy: text('updatedBy'),
    orgId: text('orgId').notNull(),
    order: integer('order').notNull().default(0),
  },
  (table) => {
    return {
      uniqueSlug: unique().on(table.cardTypeId, table.slug),
    };
  },
);

export const viewRelations = relations(cardsTableViewTable, ({ one }) => ({
  cardType: one(cardTypesTable, {
    fields: [cardsTableViewTable.cardTypeId],
    references: [cardTypesTable.id],
  }),
  cardLayouts: one(cardLayoutsTable, {
    fields: [cardsTableViewTable.layoutId],
    references: [cardLayoutsTable.id],
  }),
}));
