import { relations } from 'drizzle-orm';
import { jsonb, pgTable, text, timestamp, uuid } from 'drizzle-orm/pg-core';
import { type ICardLayoutSettings } from '../../src/Components/Cards/card-layout.type';
import { cardTypesTable } from './card-types';
import { cardsTableViewTable } from './cards-table-views';

export const cardLayoutsTable = pgTable('CardLayouts', {
  id: uuid('id').primaryKey().defaultRandom(),
  title: text('title').notNull(),
  description: text('description'),
  settings: jsonb('settings').notNull().$type<ICardLayoutSettings>(),
  cardTypeId: uuid('cardTypeId')
    .notNull()
    .references(() => cardTypesTable.id, { onDelete: 'cascade', onUpdate: 'cascade' }),
  createdAt: timestamp('createdAt').defaultNow().notNull(),
  createdBy: text('createdBy').notNull(),
  updatedAt: timestamp('updatedAt').defaultNow(),
  updatedBy: text('updatedBy'),
  orgId: text('orgId').notNull(),
});

export const cardLayoutsRelations = relations(cardLayoutsTable, ({ one, many }) => ({
  cardType: one(cardTypesTable, {
    fields: [cardLayoutsTable.cardTypeId],
    references: [cardTypesTable.id],
  }),
  cardsTableViews: many(cardsTableViewTable),
}));
