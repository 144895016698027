import { json, pgTable, text, timestamp, uuid } from 'drizzle-orm/pg-core';
import { type IAccountSettings, accountSettingsSchema } from '~/server/services/account-settings/types';

const defaultAccountSettings = accountSettingsSchema.parse({});

export const accountSettingsTable = pgTable('AccountSettings', {
  id: uuid('id').primaryKey().defaultRandom(),
  createdAt: timestamp('createdAt').defaultNow().notNull(),
  updatedAt: timestamp('updatedAt').defaultNow(),
  updatedBy: text('updatedBy'),
  createdBy: text('createdBy').notNull(),
  orgId: text('orgId').unique().notNull(),
  settings: json('settings').$type<IAccountSettings>().notNull().default(defaultAccountSettings),
});
